@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700&display=swap');

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 35px white inset !important;
}

input:-webkit-autofill{
    -webkit-text-fill-color: currentColor;
    font-size: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 3px 3px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper.swiper-horizontal .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 20px;
  right:  0;
  left: auto;
  width: 50%;
}

.fullPagination.swiper.swiper-horizontal .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .swiper.swiper-horizontal .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 100%;
  }
  .normal .swiper.swiper-horizontal .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 20px;
    bottom: auto;
  }
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background-color: #fff;
  opacity: .5;
  border-radius: 6.5px;
  transition: width .7s ease-in-out;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}

.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  width: 44px;
}

.PrivatePickersFadeTransitionGroup-root div {
  line-height: 1 !important;
  font-size: 1.2rem;
}